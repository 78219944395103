import React from 'react';

import './spinner.styles.css';

const Spinner = () => (
    <div className='spinner-overlay'>
        <div className='spinner-container' />
    </div>
);

export default Spinner;
